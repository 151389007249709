<template>
    <container id="method" :title="t('title')"  color="blue-light" :subtitle="t('subtitle')" links="true">
        <div class="method">
            <div v-for="(item, j) in method" :key="item.icon" class="method__item" :class="`method__item--${item.color}`">
                <div class="method__icon">
                    <Svg :icon="item.icon" className="method__icon--svg"/>
                </div>
                <h3>
                    {{item.title}}
                </h3>
                <p v-for="(p, i) in item.text" :key="i">
                    {{p}}                
                </p>
            </div>
        </div>

    </container>
  
</template>

<script>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import Container from './Container'
import Svg from './utils/Svg';
export default {
    components:{
        Container,
        Svg
    },
    setup(){
        const {t} = useI18n({
            messages:{
                en: require('../locales/en/method.json'),
                fr: require('../locales/fr/method.json')
            },
        })

        const method = computed(() => [
            {
                title: t('method[0].title'),
                text:[
                    t('method[0].text[0]')
                ],
                color:"blue",
                icon:"target"
            },
            {
                title: t('method[1].title'),
                text:[
                    t('method[1].text[0]')
                ],
                color:"blue-dark",
                icon:"design"
            },
            {
                title: t('method[2].title'),
                text:[
                    t('method[2].text[0]')
                ],
                color:"blue-grey",
                icon:"coding"
            },
            {
                title: t('method[3].title'),
                text:[
                    t('method[3].text[0]')
                ],
                color:"rose",
                icon:"optimize"
            }
        ]);

        return {method, t}
    }
}
</script>

<style lang="scss">

@import '../style/main';
.method{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    
    width: 100%;

    @include respond(tab-port){
        flex-direction: column;
    }
    &__item{
        padding: 4rem;
        color:$color-white;
        flex: 0 0 49%;
        margin-bottom: 2%;

         @include respond(tab-port){
            margin-bottom: 2rem;
        }
        

        & h3{
             text-transform: uppercase;
            letter-spacing: .1rem;
            font-weight: 700;
            font-size: 1.8rem;
            margin-bottom: 1.6rem;

        }

        & p {
            font-weight: 300;
            text-align: justify;
        }
        &--blue{
            background-color: $color-blue-main;
        }

         &--blue-dark{
            background-color: $color-blue-dark;
        }

        &--blue-grey{
            background-color: $color-blue-grey;
        }
         &--rose{
            background-color: $color-rose;
        }

        
    }

    &__icon{
        width: 8rem;
        height: 8rem;
        border-radius: 50%;
        background-color: rgba(255,255,255,.4);
        margin-bottom: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;


        &--svg{
            @include svg(4rem);
        }
    }
}

</style>